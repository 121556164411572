<template>
  <validation-observer ref="form" #default="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(onSaveAndExit)">
      <v-container fluid>
        <v-row
          justify="center"
          :no-gutters="$vuetify.breakpoint.smAndDown"
        >
          <v-col cols="12" xl="9" class="text-justify">
            <v-card flat class="transparent">
              <v-card-text class="pt-0">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanning.model')"
                      :items="modelList"
                      item-text="model"
                      item-value="model"
                      v-model="selectedModel"
                      @change="fetchPartByModel"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanning.variant')"
                      :items="variantList"
                      :disabled="saving || !selectedModel"
                      :loading="fetchingMatrix"
                      item-text="variant"
                      item-value="variant"
                      v-model="selectedVariant"
                      @change="filterCustomer"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanning.customer')"
                      :items="customers"
                      item-text="customername"
                      item-value="customername"
                      :disabled="saving || !selectedVariant"
                      v-model="selectedCustomer"
                      @change="filterLine"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanning.line')"
                      :items="lineList"
                      item-text="linename"
                      item-value="linename"
                      :disabled="saving || !selectedCustomer"
                      v-model="selectedLine"
                      @change="filterPart"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      :label="$t('assemblyPlanning.remark')"
                      outlined
                      :disabled="!plan"
                      v-model="plan.remark"
                      required
                    ></v-text-field>
                    <!-- <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanning.partname')"
                      :items="partList"
                      item-text="partname"
                      item-value="partname"
                      :disabled="saving || !selectedLine"
                      v-model="selectedPart"
                      return-object
                      @change="setPlanParameters"
                    ></v-autocomplete> -->
                  </v-col>
                </v-row>
                <div class="title mb-2">
                  {{ $t('assemblyPlanning.operationalParams') }}
                  <v-btn
                    small
                    outlined
                    class="text-none"
                    :disabled="!selectedMatrix || saving"
                    @click="editParams = !editParams"
                    color="primary"
                  >
                    <template v-if="!editParams">
                      <v-icon left small>mdi-pencil-outline</v-icon>
                      {{ $t('assemblyPlanning.editButton') }}
                    </template>
                    <template v-else>
                      <v-icon left small>mdi-check</v-icon>
                      {{ $t('assemblyPlanning.doneButton') }}
                    </template>
                  </v-btn>
                </div>
                <v-row v-for="macdetails in plan.machinedetails" :key="macdetails.machinename">
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="machinename"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanning.machine')"
                        type="name"
                        disabled
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        v-model="macdetails.machinename"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="cycletime"
                      rules="required|min_value:1"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanning.cycletime')"
                        type="number"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        :suffix="$t('assemblyPlanning.secs')"
                        v-model="macdetails.stdcycletime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="delaytime"
                      rules="required|min_value:0"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanning.delayTime')"
                        type="number"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-cached"
                        outlined
                        :suffix="$t('assemblyPlanning.secs')"
                        v-model="macdetails.delaytime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="title mt-4 mb-2">
                  {{ $t('assemblyPlanning.planDetails') }}
                </div>
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="quantity"
                      :rules="`required|numeric|min_value:1|multiple_of:${plan.activecavity}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanning.quantity')"
                        type="number"
                        :disabled="saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-package-variant-closed"
                        outlined
                        :suffix="$t('assemblyPlanning.pieces')"
                        v-model="plan.plannedquantity"
                        hide-details="auto"
                        @change="onQuantityChange"
                        :hint="`Should be multiple of active cavity(${plan.activecavity})`"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="scheduledstart"
                      rules="required|greater_than_now"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanning.scheduledStartTime')"
                        type="datetime-local"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-calendar-check-outline"
                        outlined
                        v-model="plan.scheduledstart"
                        hide-details="auto"
                        :disabled="saving"
                        @change="fetchEstimatedEnd"
                      >
                        <template #append-outer>
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                v-text="'$info'"
                                v-on="on"
                                v-bind="attrs"
                              ></v-icon>
                            </template>
                            <ul>
                              <li>
                                Scheduled start time is only used in calculating
                                planned vs actual performance.
                              </li>
                              <li>
                                Updating scheduled start time will not affect
                                the execution order of the plan.
                              </li>
                              <li>
                                Plans are picked automatically in FIFO fashion.
                              </li>
                              <li>
                                To update the execution order, please visit
                                <strong>re-order plans section</strong>.
                              </li>
                            </ul>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('assemblyPlanning.markAsStar')"
                      :disabled="saving"
                      v-model="plan.starred"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('assemblyPlanning.markAsTrial')"
                      :disabled="saving"
                      v-model="plan.trial"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <div>
                  {{ $t('assemblyPlanning.estimatedEnd') }}
                  <strong>{{ estimatedEndDisplay }}</strong>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="text-none"
                  :disabled="saving"
                  @click="exit"
                >
                  {{ $t('assemblyPlanning.exit') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="text-none"
                  :loading="saving || !selectedPart || !plan.plannedquantity || !plan.remark"
                  type="submit"
                >
                  {{ $t('assemblyPlanning.saveExit') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
// import { sortArray } from '@shopworx/services/util/sort.service';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'DuplicatePlan',
  data() {
    return {
      headers: [
        { text: this.$t('assemblyPlanning.part'), value: 'partname' },
        { text: this.$t('assemblyPlanning.activeCavity'), value: 'activecavity' },
        { text: this.$t('assemblyPlanning.quantity'), value: 'plannedquantity' },
      ],
      editParams: false,
      saving: false,
      fetchingMatrix: false,
      variantList: [],
      // customerList: [],
      lineList: [],
      partList: [],
      partMatrixList: [],
      selectedModel: null,
      selectedVariant: null,
      selectedCustomer: null,
      selectedLine: null,
      selectedPart: null,
      plan: {},
    };
  },
  computed: {
    ...mapState('assemblyPlanning', [
      'parts',
      'partMatrix',
      'selectedPlan',
      'customerList',
    ]),
    ...mapGetters('assemblyPlanning', ['selectedAsset', 'partMatrixTags']),
    ...mapGetters('helper', ['locale']),
    modelList() {
      return this.parts;
    },
    customers() {
      return this.customerList;
    },
    estimatedEndDisplay() {
      let res = '';
      if (this.plan.scheduledend) {
        res = formatDate(
          new Date(this.plan.scheduledend),
          'PPpp',
          { locale: this.locale },
        );
      }
      return res;
    },
    selectedMatrix() {
      if (this.selectedPart && this.selectedLine) {
        const partMatrix = this.partMatrixList.filter((matrix) => (
          matrix.partname === this.selectedPart.partname
          && matrix.linename === this.selectedLine
        ));
        return partMatrix.sort((a, b) => a.sequence - b.sequence);
      }
      return null;
    },
    shots() {
      if (this.plan.plannedquantity && this.plan.activecavity) {
        return +this.plan.plannedquantity / +this.plan.activecavity;
      }
      return 0;
    },
  },
  async created() {
    this.clear();
    await this.fetchCustomerList();
    this.setPlan();
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('assemblyPlanning', [
      'fetchPartMatrix',
      // 'isFamilyMold',
      // 'getFamilyParts',
      'getScheduledEnd',
      'fetchLastPlan',
      'createPlans',
      'fetchModel',
      'fetchCustomerList',
    ]),
    initPlan() {
      this.plan = {
        assetid: 0,
        partname: '',
        linename: '',
        activecavity: 1,
        machinedetails: [],
        plannedquantity: 0,
        scheduledstart: formatDate(new Date(Math.ceil(new Date() / 9e5) * 9e5), 'yyyy-MM-dd\'T\'HH:mm'),
        scheduledend: '',
        starred: false,
        trial: false,
        sortindex: 0,
        status: 'notStarted',
        remark: '',
      };
    },
    async setPlan() {
      const [plan] = this.selectedPlan;
      this.selectedModel = this.modelList
        .find((p) => p.model === plan.model).model;
      this.fetchingMatrix = true;
      this.variantList = await this.fetchModel({ model: this.selectedModel });
      this.selectedVariant = this.variantList
        .find((p) => p.variant === plan.variant)
        .variant;
      this.filterCustomer();
      this.selectedCustomer = this.customers
        .find((p) => p.customername === plan.customer)
        .customername;
      this.filterLine();
      this.selectedLine = this.variantList
        .find((m) => m.linename === plan.linename)
        .linename;
      this.filterPart();
      this.selectedPart = this.variantList
        .find((p) => p.partname === plan.partname);
      this.fetchingMatrix = false;
      await this.setEditParams(plan);
    },
    async setEditParams(plan) {
      this.plan = {
        // eslint-disable-next-line
        assetid: plan.assetid,
        partname: plan.partname,
        model: plan.model,
        variant: plan.variant,
        customer: plan.customer,
        linename: plan.linename,
        activecavity: plan.activecavity,
        machinedetails: plan.machinedetails,
        plannedquantity: plan.plannedquantity,
        starred: plan.starred,
        trial: plan.trial,
        scheduledstart: formatDate(new Date(Math.ceil(new Date() / 9e5) * 9e5), 'yyyy-MM-dd\'T\'HH:mm'),
        scheduledend: '',
        sortindex: 0,
        status: 'notStarted',
        remark: plan.remark,
      };
      await this.fetchEstimatedEnd();
    },
    clear() {
      this.initPlan();
      this.editParams = false;
      this.variantList = [];
      // this.customerList = [];
      this.lineList = [];
      this.partList = [];
      this.selectedModel = null;
      this.selectedVariant = null;
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async fetchPartByModel() {
      this.initPlan();
      this.variantList = [];
      // this.customerList = [];
      this.lineList = [];
      this.partList = [];
      this.selectedVariant = null;
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      if (this.selectedModel) {
        this.fetchingMatrix = true;
        this.variantList = await this.fetchModel({ model: this.selectedModel });
        this.fetchingMatrix = false;
      }
    },
    filterCustomer() {
      this.initPlan();
      // this.customerList = [];
      this.lineList = [];
      this.partList = [];
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      // if (this.selectedVariant) {
      //   this.customerList = this.variantList
      //     .filter((variant) => variant.variant === this.selectedVariant);
      // }
    },
    filterLine() {
      this.initPlan();
      this.lineList = [];
      this.partList = [];
      this.selectedLine = null;
      this.selectedPart = null;
      if (this.selectedCustomer) {
        // this.lineList = this.variantList;
        this.lineList = this.variantList
          .filter((variant) => variant.variant === this.selectedVariant);
      }
    },
    filterPart() {
      this.initPlan();
      this.partList = [];
      this.selectedPart = null;
      if (this.selectedLine) {
        this.partList = this.lineList
          .filter((line) => line.linename === this.selectedLine);
        this.partMatrixList = this.partList;
        [this.selectedPart] = this.partList;
        this.setPlanParameters();
      }
    },
    async setPlanParameters() {
      this.initPlan();
      if (this.selectedPart && this.selectedLine) {
        this.plan.assetid = this.selectedPart.assetid;
        this.plan.partname = this.selectedPart.partname;
        this.plan.linename = this.selectedLine;
        this.plan.model = this.selectedModel;
        this.plan.variant = this.selectedVariant;
        this.plan.customer = this.selectedCustomer;
        this.plan.activecavity = 1;
        this.plan.machinedetails = [];
        this.selectedMatrix.forEach((part) => {
          const {
            machinename,
            stdcycletime,
            linename,
            delaytime,
          } = part;
          this.plan.machinedetails.push({
            machinename,
            stdcycletime,
            linename,
            delaytime,
          });
        });
      }
    },
    async fetchEstimatedEnd() {
      if (this.plan.plannedquantity && this.plan.scheduledstart) {
        const machineCount = this.plan.machinedetails.length;
        const totalStdCycletime = this.plan.machinedetails.reduce((acc, cur) => {
          acc += (+cur.stdcycletime * 1000);
          return acc;
        }, 0);
        let ctAvg = 0;
        if (machineCount !== 0) {
          ctAvg = Math.floor(totalStdCycletime / machineCount);
        }
        const runTime = ((+this.plan.plannedquantity - 1)
          * ctAvg) + totalStdCycletime;
        this.plan.linestdcycletime = (runTime / 1000);
        this.plan.scheduledend = await this.getScheduledEnd({
          start: new Date(this.plan.scheduledstart).getTime(),
          duration: runTime,
        });
      } else {
        this.plan.scheduledend = '';
      }
    },
    // onCavityChange() {
    //   if (this.familyParts && this.familyParts.length) {
    //     this.updateFamilyParams();
    //   }
    // },
    onQuantityChange() {
      this.fetchEstimatedEnd();
      // if (this.familyParts && this.familyParts.length) {
      //   this.updateFamilyParams();
      // }
    },
    // updateFamilyParams() {
    //   this.familyParts = this.familyParts.map((p) => ({
    //     ...p,
    //     plannedquantity: +p.activecavity * this.shots,
    //   }));
    // },
    // updateFamilyCavity({ id, payload }) {
    //   // eslint-disable-next-line
    //   const index = this.familyParts.findIndex((p) => p._id === id);
    //   this.$set(this.familyParts, index, {
    //     ...this.familyParts[index],
    //     plannedquantity: +payload * this.shots,
    //   });
    // },
    async setSortIndex() {
      const lastPlan = await this.fetchLastPlan();
      if (lastPlan) {
        this.plan.sortindex = lastPlan.sortindex + 100;
      } else {
        this.plan.sortindex = 100;
      }
    },
    exit() {
      this.clear();
      this.selectedPart = null;
      this.$router.go(-1);
    },
    async save() {
      await this.setSortIndex();
      // const matrixTags = this.partMatrixTags(this.plan.assetid);
      // const partMatrix = matrixTags.reduce((acc, cur) => {
      //   acc[cur.tagName] = this.selectedMatrix[cur.tagName];
      //   return acc;
      // }, {});
      const payload = [{
        // ...partMatrix,
        ...this.plan,
        scheduledstart: new Date(this.plan.scheduledstart).getTime(),
        // familyName: 'family',
      }];
      // eslint-disable-next-line
      // const ids = [...new Set(this.selectedFamilyParts.map((p) => p._id))];
      // eslint-disable-next-line
      // this.selectedFamilyParts = this.familyParts.filter((p) => ids.includes(p._id));
      // if (this.selectedFamilyParts && this.selectedFamilyParts.length) {
      //   const familyPayload = this.selectedFamilyParts.map((p) => ({
      //     ...payload[0],
      //     cavity: p.cavity,
      //     activecavity: p.activecavity,
      //     plannedquantity: p.plannedquantity,
      //     partname: p.partname,
      //   }));
      //   payload = [...payload, ...familyPayload];
      // }
      const created = await this.createPlans(payload);
      if (created) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'PLAN_CREATED',
        });
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ERROR_CREATING_PLAN',
        });
      }
      return created;
    },
    async onSaveAndExit() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.saving = true;
        const created = await this.save();
        if (created) {
          this.exit();
        }
        this.saving = false;
      }
    },
  },
};
</script>
